import { render, staticRenderFns } from "./OrganizationPrintSetup.vue?vue&type=template&id=6d4e9adb&scoped=true"
import script from "./OrganizationPrintSetup.vue?vue&type=script&lang=js"
export * from "./OrganizationPrintSetup.vue?vue&type=script&lang=js"
import style0 from "./OrganizationPrintSetup.vue?vue&type=style&index=0&id=6d4e9adb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d4e9adb",
  null
  
)

export default component.exports