<template>
    <div v-show="false" id="printMe" v-if="printOptionsReady && organization.profiles" style="position: relative">
        <div style="position: absolute; z-index: -999; width: 100%; overflow-y: hidden">
            <div style="height: 1000px; width: 100%"
                 class="d-flex justify-content-center align-items-end">
                <img src="../../../../../public/img/Recruiting-Booklet-2018-1.png" style="max-height: 40%; max-width: 40%; opacity: 0.05" alt=""/>
            </div>
        </div>
        <div class="w-100 d-flex justify-content-between" style="padding: 7px 7px 0 7px">
            <div>
                <div style=" position: absolute; z-index: 999; height: 95px; width: 90px"
                     class="d-flex justify-content-center align-items-center">
                    <img src="../../../../../public/img/Recruiting-Booklet-2018-1.png" style="max-height: 100%; max-width: 100%" alt=""/>
                </div>
                <div style="padding-left: 100px;font-size: 28px">
                    <div><b>Rockford Police Department</b></div>
                </div>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-end" style="font-size: 12px">
                <div><b style="color: red">Not for media release</b></div>
                <div><b style="color: red">INFORMATION ONLY - MAKE YOUR OWN CASE</b></div>
            </div>
        </div>
        <div style="height: 5px"/>

        <!-- RIBBON -->
        <h3 style="text-align: center;">
            <span style="display: flex; align-items: center; height: 50px">
                <span class="bg-dark" style="flex: 1; height: 100%; display: flex; align-items: center">
                    <span style="margin: 0; font-size: 40px ;width: 100%; text-align: center">
                        <b>{{orgName}}</b>
                    </span>
                </span>
            </span>
        </h3>
        <div style="height: 5px"/>

<!--        &lt;!&ndash; NAME &ndash;&gt;
        <h2 style="text-align: center; border-top: #000000 solid 3px; border-bottom: #000000 solid 3px; padding: 0">
            <b>{{organization.orgName || "UNKNOWN"}}</b>
        </h2>-->

        <!-- Profiles -->
        <div style="padding: 0; margin: 7px">
            <div v-for="row in rows" class="d-flex justify-content-center" style="page-break-inside: avoid; break-inside: avoid">
                <div v-for="member in getRow(row)" v-if="member" style="width: 210px; height: 260px; margin: 5px; padding: 0">
                    <a :href="'http://corweb/Police/CA/Shared Documents/Subject Info Sheets/' + (member.lastName !== null ? (member.lastName.charAt(0) + member.lastName.slice(1).toLowerCase()) : '') + ', ' + (member.firstName !== null ? (member.firstName.charAt(0) + member.firstName.slice(1).toLowerCase()) : '') + ' ' + (member.middleName !== null ? member.middleName.charAt(0) : '') + (member.suffix !== null ? ' ' + (member.suffix.charAt(0) + member.suffix.slice(1).toLowerCase()) : '') + '.pdf'"
                          style="text-decoration: inherit !important; color: inherit">
                        <b-card style="width: 100%; height: 100%; text-align: center; font-size: 12px" class="d-flex flex-column" no-body>
                            <div v-if="member.ribbons && member.ribbons[0]" :class="'bg-' + ribbonVariant(member.ribbons[0].ribbon)">
                                <b>{{member.ribbons[0].ribbon}}</b>
                            </div>
                            <div v-else style="height: 1.5em"></div>
                            <div style="background-color: black; width: 100%; height: 150px" class="d-flex align-items-center">
                                <b-card-img v-auth-image="getObjectImage(member.images, false)" style="object-fit: contain; height: 100%; width: 100%"/>
                            </div>
                            <div>
                                <b>{{member.lastName || "UNKNOWN"}}, {{member.firstName || "UNKNOWN"}} {{member.middleName ? " " + member.middleName : ""}}</b>
                            </div>
                            <div>
                                {{member.streetName && Array.isArray(member.streetName) && member.streetName[0] && member.streetName[0].streetName ? member.streetName[0].streetName : "No Street Name"}}
                            </div>
                            <div>
                                {{member.birthMonth || "XX"}}/{{member.birthDay || "XX"}}/{{member.birthYear || "XXXX"}}
                            </div>
                            <div>
                                {{member.socialMedia.find(_ => _.site === "FACEBOOK") ? member.socialMedia.find(_ => _.site === "FACEBOOK").userName : "No Facebook"}}
                            </div>
                        </b-card>
                    </a>
                </div>
            </div>
        </div>

        <!-- Footer -->
        <div style="padding: 10px; font-weight: bold; page-break-inside: avoid; break-inside: avoid;">
            <div style="color: red; text-align: center; width: 100%">
                CONFIDENTIAL - LAW ENFORCEMENT SENSITIVE - DO NOT DISSEMINATE FURTHER WITHOUT PERMISSION FROM ORIGINATING AGENCY
            </div>
            <div style="text-align: center">
                <div>Produced: {{new Date().toJSON().slice(5,10).replace(/-/g,'/') + "/" + new Date().toJSON().slice(0,4)}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import risc from "@/services/risc";

export default {
    name: "OrganizationPrint",
    props: {
        printOptions: {type: Object},
        organization: {type: Object},
        orgName: {type: String},
    },
    data: () => {
        return {
            printOptionsReady: false,
            rows: 0,
        }
    },
    watch: {
        printOptions() {
            if (this.printOptions && this.printOptions.members) {
                this.rows = Math.ceil(this.printOptions.members.length / 5)
                this.printOptionsReady = true;
            } else {
                this.rows = 0;
                this.printOptionsReady = false;
            }
        }
    },
    methods: {
        getRow(row) {
            if (!(this.printOptions.members || this.printOptions.members.length !== 0)) {
                return [];
            }
            let start = (row - 1) * 5;
            let end = start + 5;
            if (end <= this.printOptions.members.length) {
                return this.printOptions.members.slice(start, end);
            } else {
                return this.printOptions.members.slice(start);
            }

        },
        ribbonVariant(ribbon) {
            switch (ribbon) {
                case 'IN CUSTODY':
                    return 'success';
                case 'PAROLE/PROBATION':
                    return 'warning text-dark';
                case 'WANTED':
                    return 'danger';
                case 'OUT OF TOWN':
                    return 'warning text-dark';
                case 'DECEASED':
                    return 'dark';
                default:
                    return 'primary';
            }
        },
        getObjectImage(images) {
            if (images && images.length >= 1) {
                return this.getFirstDocumentImage(images);
            }
            return ('/img/NoPictureOnFileGrey.png');
        },
        getFirstDocumentImage(docArray) {
            let stId = 0;
            docArray.forEach(doc => {
                if (doc.arrayOrder === 0) {
                    stId = doc.storageFileName;
                }
            })
            if (stId !== 0) {
                return risc.getImage(stId);
            } else {
                return risc.getImage(docArray[0].storageFileName);
            }
        },
    }
}
</script>

<style scoped>

</style>
