<template>
    <div>
        <b-modal class="modal-dark" id="print-org" no-close-on-backdrop scrollable title="Print Organization" @close="close()">

            <b-input-group class="input-group-sm" prepend="Org name: ">
                <b-form-input v-model="editOrgName"></b-form-input>
            </b-input-group><br>

            <v-client-table :columns="columns" :data="data" :options="dataTableOptions" class="dataTable">
                <div slot="afterFilterWrapper" class="filterWrapper" >
                    <span style="padding-top: 5px"><b>Select All &nbsp;</b></span>
                    <b-checkbox v-model="allMarked" :indeterminate="intermediate" @change="unmarkAndMarkAll()" size="lg"></b-checkbox>
                </div>

                <div slot="select" slot-scope="props">
                        <b-checkbox size="sm" v-model="options.members" :value="props.row">
                        </b-checkbox>
                </div>
            </v-client-table>

            <!--Cancel and Submit Buttons-->
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="close()">Close</b-button>
                <b-button size="sm" variant="primary" :disabled="!allMarked && !intermediate" @click="print()">Print</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "OrganizationPrintSetup",
    props: {
        organization: {type: Object},
        open: {type: Boolean},
        orgName: {type: String},
    },
    data: () => {
        return {
            options: null,
            allMarked: false,
            intermediate: false,
            columns: ["select", "member"],
            data: [],
            dataTableOptions: {
                sortable: ["member"],
                filterable: ["member"],
                orderBy: {column: 'member', ascending: true},
                headings: {
                    member: "Member",
                },
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 25,
                skin: 'table table-striped table-hover',
            },
            editOrgName: ""
        }
    },
    watch: {
        open() {
            if (this.open) {
                if (this.updateOptions()) {
                    this.$bvModal.show('print-org');
                }
            }
            this.createFullName();
            this.editOrgName = this.orgName;
        },
        options: {
            deep: true,
            handler() {
                if (this.options && this.options.members && Array.isArray(this.options.members)) {
                    switch (this.options.members.length) {
                        case this.organization.profiles.length:
                            this.intermediate = false;
                            this.allMarked = true;
                            break;
                        case 0:
                            this.intermediate = false;
                            this.allMarked = false;
                            break;
                        default:
                            this.intermediate = true;
                            this.allMarked = false;
                    }
                }
            },
        },

    },
    methods: {
        print() {
            this.$emit('getPrintOptions', this.options);
            this.$emit('print', this.editOrgName);
        },
        close() {
            this.$bvModal.hide('print-org')
            this.$emit('closed');
        },
        updateOptions() {
            this.options = Object.assign({}, {
                members: []
            })
            return true;
        },
        unmarkAndMarkAll() {
            this.options.members = []
            if (this.allMarked) {
                this.organization.profiles.forEach(profile => {
                    // for (let i = 0; i < 4; i++)  // Simulate more profiles (check all then check doesn't work properly)
                        this.options.members.push(Object.assign({}, profile))
                })
            }
        },
        createFullName() {
            this.data = [];
            let firstName, lastName, middleName, streetName, pushMe;
            this.organization.profiles.forEach( _ => {
                if (_.firstName){firstName = _.firstName;} else {firstName = "UNKNOWN"}
                if (_.lastName){lastName = _.lastName;} else {lastName = "UNKNOWN"}
                if (_.middleName){middleName = _.middleName;} else {middleName = "UNKNOWN"}
                try {
                    if (_.streetName) {
                        if (_.streetName[0].streetName) {
                            streetName = "(" + _.streetName[0].streetName + ")";
                        }
                    }
                }
                catch{
                    streetName = "";
                }
                pushMe = Object.assign(_, {member: lastName + ", " + firstName + " " + middleName + " " + streetName})
                this.data.push(pushMe);
            })
        }
    }
}
</script>

<style scoped>
    .filterWrapper {
        display: flex;
        justify-content: flex-end;
        padding-top: 3px;
    }
</style>